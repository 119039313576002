/*stepline*/
#stepline {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
  }

.line {
    width: 100%;
    height: 2px;
  }
  .line:hover {
    cursor: pointer;
  }
  .line .step {
    background-color: #fff;
    border-radius: 50%;
    float: left;
    position: absolute;
    text-align: center;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    top: 0;
    border: 1px solid gray;
  }
  .line .step::before {
    content: attr(data-timelinelabel);
    position: absolute;
    top: -75px;
    left: -5px;
    width: 150px;
    transform: rotateZ(-45deg);
    display: block;
    font-size: 0.8em;
    font-weight: bold;
  }
  .line .step::after {
    content: attr(data-mobiletext);
    font-size: 0.6rem;
    position: absolute;
    top: 23px;
    left: -48px;
    padding: 3px;
    width: 120px;
  }
  .line .quarter {
    background-color: #ddd;
    border-radius: 50%;
    float: left;
    position: absolute;
    left: 5%;
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    top: 5px
  }
  .line .step a:hover {
    color: #ccc;
    text-decoration: none;
  }
  .line .quarter a {
    display: none;
    user-select: none;
  }
  .line .complete {
    background-color: #45D681;
  }
  .line .step.complete {
    border-color:transparent;
  }
  .line .dot a {
    user-select: none;
  }
  .line .complete a{
    text-decoration: none;
    text-indent: -9999px;
    color: transparent;
  }
  .line .dot.active {
    background-color: #45D681;
    cursor: pointer;
  }
  .line .dot.active a{
    text-indent: -9999px;
    color: transparent;
  }
  
  
  @media all and (min-width: 481px) {
    .dot:hover {
      /*background-color:rgb(40, 211, 111);*/
      cursor: pointer;
    }
  }
  .steps-line-container {
    overflow: hidden;
  }
  