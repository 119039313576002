.intro,
.intro-overview,
.overview,
.how-it-works,
.subscription {
  font-family: 'Montserrat', sans-serif;
}

/* Intro section start*/

.intro h1 {
  font-size: 10.5rem;
  line-height: 1.5;
}
.btn-orange {
  background: #ed5331;
  color: #fff;
}
.intro .container {
  background: url('../images/home/transp_lines_with_rocket.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
}
section .container > .row {
  margin: 0 auto;
}
.mb-7 {
  margin-bottom: 7rem;
}
/* Intro section start*/
.intro-secondary-txt {
  font-style: normal;
  font-weight: normal;
  font-size: 2.8rem;
  line-height: 2.8rem;
  letter-spacing: 0.395em;
  color: #242a38;
}
.intro h1 {
  font-size: 3rem;
}
.intro-txt {
  font-style: normal;
  font-weight: normal;
  font-size: 1.35rem;
  line-height: 50px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}
.intro-rotate {
  display: inline-block;
  font-size: 2.8rem;
  text-align: center;
  letter-spacing: -0.015em;
  color: #f15a24;
  vertical-align: top;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.intro-main-txt {
  font-weight: 900;
  font-style: normal;
  line-height: 80px;
}
.animated {
  font-size: 8rem;
  letter-spacing: 0.145em;
  margin: 0;
  padding: 0;
  background: url(../images/home/text_bg.png) repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: moveBg 90s linear infinite;
  -webkit-animation: moveBg 90s linear infinite;
}
.indent-left {
  padding-left: 7rem;
}
@keyframes moveBg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 75%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Intro section end*/
/* Overview start*/
.overview h2,
.how-it-works h2 {
  font-size: 2.5rem;
}
.overview p {
  font-size: 1.8rem;
  font-weight: normal;
}
/* Overview End*/
/* Table styles Start */
.table.table-features {
  font-size: 1.6rem;
}
.table-features th ul {
  list-style: none;
  text-align: left !important;
  padding-left: 0;
}
.table-features td ul {
  list-style: none;
  padding-left: 0;
}
.table.table-features td,
.table.table-features th {
  border-top-width: 0;
}
.table.table-features thead th:first-child div {
  font-weight: bold;
  font-size: 1.6rem;
}
.table.table-features tbody th,
.table.table-features tbody td {
  height: 210px;
  padding: 0 8px;
}
.table.table-features thead th {
  height: 120px;
  padding: 0 8px;
  border-bottom: 10px solid transparent;
}
.table.table-features thead div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 1.2rem;
}
.table.table-features thead div img {
  max-width: 55px;
  margin: auto 5px;
}
.table.table-features thead div span {
  margin-right: 5px;
}
.table.table-features thead th > div {
  height: 100%;
}
.table.table-features tbody th > div,
.table.table-features tbody td > div {
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.table.table-features tbody img {
  width: 40px;
  margin: 5px;
}
.table.table-features tbody tr h5 {
  font-weight: bold;
  height: 50px;
  padding-top: 20px;
}
.table.table-features tbody tr td ul {
  padding-top: 60px;
}
.table.table-features tbody tr ul {
  font-size: 1.4rem;
  font-weight: 300;
}
.table.table-features tbody tr li {
  margin: 10px;
}
.border-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.border-t {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.bg-table-1 {
  background: #050912;
}
.bg-table-2 {
  background: #212735;
}
.bg-table-3 {
  background: #283347;
}
.bg-table-3 {
  background: #283347;
}
.bg-table-4 {
  background: #182743;
}
.bg-table-5 {
  background: #f5f5f5;
}
.txt-orange {
  color: #ed5331;
}
.txt-gray {
  color: #555;
}
/* Table styles End */
.real-time .container {
  background: linear-gradient(#050a12, #1d2f51, #2c394f);
}
.examples .container {
  background: linear-gradient(
    0deg,
    #070b17,
    #142139 35%,
    #283650 65%,
    #fff 65%
  );
}
.examples .col-md-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.homepage-image {
  width: 350px;
}

/* Footer Start */
.subscription .submit {
  background: #3aa0fb;
  padding: 10px 50px;
  font-size: 0.8rem;
}
.subscription input {
  border-color: #999999;
}
.copyright {
  font-size: 0.9rem;
}
.copyright img {
  width: 44px;
}
/* Footer End */

/* Media > 992*/
@media all and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 2rem;
  }
}
/* Media < 992*/
@media all and (max-width: 992px) {
  .intro h1 {
    font-size: 3rem;
  }
  .intro .container {
    background: none;
  }
  .animated {
    font-size: 5rem;
  }
  .intro-rotate {
    font-size: 1.6rem;
  }
  .intro-secondary-txt {
    font-size: 1.6rem;
    text-align: center;
  }
  .intro-main-txt {
    text-align: center;
  }
  .overview .col-12 {
    overflow: auto;
  }
  .table.table-features thead th:first-child div {
    font-size: 1.2rem;
  }
  .table.table-features thead div,
  .table.table-features tbody tr h5 {
    font-size: 1rem;
  }
  .table.table-features tbody tr ul {
    font-size: 0.9rem;
  }
  .table.table-features tbody img {
    width: 30px;
    margin: 4px;
  }
  .table.table-features tbody tr li {
    margin: 1px;
  }
  .table.table-features tbody tr:nth-child(2) td li,
  .table.table-features tbody tr:nth-child(3) td li {
    margin-bottom: 20px;
  }
}
/* Media < 540px*/
@media all and (max-width: 540px) {
  .indent-left {
    padding-left: 0;
  }
  .animated {
    font-size: 4rem;
  }
  .intro-rotate {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  .intro-main-txt {
    line-height: 55px;
  }
  .intro h1 {
    font-size: 2.1rem;
  }
  .intro-txt {
    line-height: 1.5;
    font-size: 1.2rem;
  }
}
/* Media > 1200px */
@media (min-width: 1200px) {
  /* Override bootstrap container width for desktop */
  .container {
    max-width: 1440px;
  }
  .animated,
  .intro-secondary-txt {
    margin-bottom: 1.6rem;
  }
}
