/* Sections font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');

html {
  height: calc(100% - 72px);

  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f7f8f8;
}

#root {
  height: 100%;
}
/*Header*/
.navbar.bg-dark,
header {
  background: #212735 !important;
  box-shadow: 0px 1px 4px #27272780;
  -webkit-box-shadow: 0px 1px 4px #27272780;
  -moz-box-shadow: 0px 1px 4px #27272780;
}
.navbar {
  padding: 0 1rem;
}
.btn-round {
  border: 2px solid #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.my-account {
  color: #fff;
  background: #ed5331;
  padding: 0;
}
.my-account:hover {
  color: #aaa;
  background: #fff;
}
.my-account span {
  line-height: 2.7;
}
.btn-new {
  background: #41c0cd;
  border-color: #41c0cd;
}
.btn-new:hover,
.btn-new:focus,
.btn-success.btn-new:not(:disabled):not(.disabled):active {
  background: #41c0cd;
  border-color: #41c0cd;
}
/* Header End*/
.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: 'Montserrat', sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

#sidebar {
  background-color: #82889b;
  height: 100%;
  display: inline-block;
  float: none !important;
}

#sidebar li a {
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-image {
  max-width: 150px;
}

.icon {
  width: 18px;
  height: 18px;
  margin-left: 1em;
}

.search-label {
  display: block;
}

#table-campaigns .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

#table-campaigns .dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

.template-thumbnail {
  max-width: 90%;
  border-radius: 15px;
  background-color: white;
}

.no-left-padding {
  padding-left: 0px;
}
